import React from "react"

const ElementsSection = () => (

  <section id="Buttons" className="wrapper guide" >
  <div className="padded">
    <h1> Elements</h1>
    <div className="wrapper spaced-lg">
      <h2>Buttons</h2>
      <p className="row">Currently there are only two buttons styles implemented:</p>

      <div className="wrapper padded md">
        <button className="cta">CTA Button</button>
        <a href="#0" className="button">Standard Button</a>

      </div>
      <p className="row">The app will probably have to extend this by at least three more styles:</p>

      <div className="wrapper padded">
        <button className="danger">Danger Button</button>
        <button className="action">Action Button</button>
        <button className="disabled">Disabled Button</button>
      </div>

      <p className="row">These styles translate into the following</p>

      <div className="wrapper padded">
        <button><i class="fas fa-edit"></i> Bearbeiten</button>
        <button className="disabled"><i class="fas fa-edit"></i> Bearbeiten</button>
        <button><i class="fas fa-file-pdf"></i> Öfnnen</button>
        <button className="disabled"><i class="fas fa-file-pdf"></i> Öfnnen</button>
        <button>Passwort ändern</button>
        <button>Abmelden</button>
        <button className="action"><i class="far fa-save"></i> Speichern</button>
        <button className="disabled"><i class="far fa-save"></i> Speichern</button>
        <button className="action"> Senden</button>
        <button className="danger"><i class="fas fa-trash"></i> Löschen</button>
        <button className="danger">Konto löschen</button>
      </div>
    </div>

    <div className="wrapper spaced-lg">
      <h2 >Dropdown</h2>
      <p className="row">These dropdowns are barely styled in order to be unintrusive. This way, the native OS styling will be applied.</p>
      <div className="wrapper padded md">
        <div className="block25">
          <h3>Active</h3>
          <select type="select">
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
            <option>Option 4</option>
          </select>
        </div>
        <div className="block25">
          <h3>Disabled</h3>
          <select type="select" className="disabled">
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
            <option>Option 4</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</section>
)

export default ElementsSection